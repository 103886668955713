import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phoneCustomInput.css'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface PhoneCustomInputProps {
  value: string
  error?: string
  onChange: (newValue) => void
  isEditing: boolean
  height?: string
  mode?: 1 | 2
  label?: string | React.ReactNode
  onBlur?: () => any
  nextButton?: any
}
const PhoneCustomInput = ({
  value,
  error,
  onChange = () => {},
  isEditing = true,
  height = 'h-14',
  mode = 1,
  label = '',
  onBlur = () => {},
  nextButton,
}: PhoneCustomInputProps) => {
  const { t } = useTranslation()

  const modeOneProps = {
    inputClass: `!w-full !${height} !border-0 ${
      !!error && '!border-[#f31260] hover:!bg-redError focus:!bg-redError'
    } !rounded-2xl !pl-6 !text-base ${
      isEditing
        ? 'text-black/90 !bg-mediumGray'
        : 'text-lightGray !bg-white !border-1 !border-gray-200'
    } !ml-auto`,

    buttonClass: `!rounded-2xl !border-0 w-24 flex !justify-center !relative ${
      !!error && '!border-[#f31260] hover:!bg-redError focus:!bg-redError'
    } ${
      isEditing
        ? '!bg-mediumGray'
        : '!bg-white [&>div]:!cursor-default !border-1 !border-gray-200'
    }`,
  }

  const modeTwoProps = {
    inputClass: `!w-full !${height} !border-2 ${
      !!error
        ? '!border-[#f31260] hover:!bg-redError focus:!bg-redError'
        : '!border-gray-200'
    } !rounded-xl !pl-3 !text-xs ${
      isEditing ? 'text-black/90 !bg-white' : 'text-lightGray !bg-white'
    } !ml-auto`,

    buttonClass: `!rounded-xl !border-0 w-20 flex !justify-center !relative !border-2 ${
      !!error
        ? '!border-[#f31260] hover:!bg-redError focus:!bg-redError'
        : '!border-gray-200'
    } ${isEditing ? '!bg-white' : '!bg-white [&>div]:!cursor-default'}`,
  }

  return (
    <div className="z-40">
      {typeof label == 'string' ? (
        <p className={`text-sm mb-1 ${!!error && 'text-[#f31260]'}`}>
          {label} <span className="text-red-500">*</span>
        </p>
      ) : typeof label != 'undefined' ? (
        label
      ) : null}
      <PhoneInput
        preferredCountries={['pa', 'co', 'us', 'cr', 've']}
        masks={{ pa: '.... ....' }}
        disabled={!isEditing}
        containerClass={`!flex !flex-row-reverse gap-x-4`}
        inputClass={
          mode === 1 ? modeOneProps.inputClass : modeTwoProps.inputClass
        }
        buttonClass={
          mode === 1 ? modeOneProps.buttonClass : modeTwoProps.buttonClass
        }
        dropdownClass={'!left-0 !top-10'}
        countryCodeEditable={!value}
        country={'pa'}
        value={value}
        onChange={(value, country: any, e, formattedValue) =>
          onChange(formattedValue.replaceAll(' ', ''))
        }
        onBlur={onBlur}
        specialLabel={t('bookingDetailsPage.phone')}
        placeholder={t('bookingDetailsPage.phonePlaceHolder')}
        inputProps={{ type: 'tel' }}
      />
      {/* {!!error && (
        <p className=" text-rose-500 mt-1 text-xs font-semibold">{error}</p>
      )} */}
    </div>
  )
}
export default PhoneCustomInput
